$font-family-uikit-sans-serif: "Poppins", sans-serif;
$font-family-uikit-headings: "BebasNeue", "AvenirNextCondensed-Bold",
  "Futura-CondensedExtraBold", HelveticaNeue-CondensedBold, Arial Narrow,
  Helvetica Neue, sans-serif-condensed, sans-serif;

// Components
$button-spacing-vertical: 19px;
$button-spacing-horizontal: 16px;

$button-border-radius: 8px;
$button-border-color: transparent;
$button-text-transform: none;
$button-trube-border-width: 2px;

$button-trube-text-transform: normal; // uppercase;

$checkbox-trube-width: 24px;
$checkbox-trube-height: 24px;
$checkbox-trube-border-radius: 8px;
$checkbox-trube-top: 0;
$checkbox-trube-left: 2px;
$checkbox-trube-toggle-width: 10px;
$checkbox-trube-toggle-height: 8px;
$checkbox-trube-toggle-top: 8px;
$checkbox-trube-toggle-left: 9px;

$checkbox-trube-small-width: 16px;
$checkbox-trube-small-height: 16px;
$checkbox-trube-small-border-radius: 4px;
$checkbox-trube-small-top: 4px;
$checkbox-trube-small-left: 6px;
$checkbox-trube-small-toggle-width: 7px;
$checkbox-trube-small-toggle-height: 6px;
$checkbox-trube-small-toggle-top: 9px;
$checkbox-trube-small-toggle-left: 11px;

$checkbox-trube-big-width: 32px;
$checkbox-trube-big-height: 32px;
$checkbox-trube-big-border-radius: 8px;
$checkbox-trube-big-top: 0px;
$checkbox-trube-big-left: 2px;
$checkbox-trube-big-toggle-width: 16px;
$checkbox-trube-big-toggle-height: 13px;
$checkbox-trube-big-toggle-top: 9px;
$checkbox-trube-big-toggle-left: 11px;

// Link hover decoration
$link-hover-decoration: none;

$trube-hover-decoration: $link-hover-decoration;

$moving-bar-trube-gap: 16px;
$moving-bar-trube-speed: 40s;
